.preview-scientific-profile {
    h4 {
      margin-top: 7px;
      margin-bottom: 7px;
      color: #0000ff;
      font-size: 1.25rem;
      font-weight: bold;
    }

    table {
      font-family: 'Times New Roman', Times, serif !important;
      font-size: 1.125rem;
    }
  }