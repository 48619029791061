.specialize-review-detail {
  font-family: 'Times New Roman', Times, serif !important;
}

.specialize-review-form {
  font-family: 'Times New Roman', Times, serif !important;

  .p-button-next {
    background-color: var(--cyan-600);
    border-color: var(--cyan-600);
    color: white;
    font-weight: 500;
    &:hover {
      background-color: var(--cyan-700);
      border-color: var(--cyan-700);
    }
  }

  .btn-control {
    display: block;
    width: 7rem;
    text-align: center;
  }

  h4 {
    margin-top: 7px;
    margin-bottom: 7px;
    color: #0000ff;
    font-size: 1.25rem;
    font-weight: bold;
  }

  table {
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 1.125rem;
  }

  fieldset {
    min-width: auto;
  }

  fieldset:disabled {
    opacity: 0.9;
  }
}
