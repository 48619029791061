.form-table {
  overflow-x: auto;
  @include scrollbars(0.7em, rgb(190, 196, 202), rgb(238, 238, 238));
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  thead th {
    background-color: #f8f9fa;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 5px;
  }
  .field {
    margin: 0 !important;
  }
  .float {
    position: sticky;
    top: 0;
    right: -1px;
    z-index: 2;
  }
  td.float {
    background-color: #fff;
  }
}
