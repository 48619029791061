.view-history-midterm-table {
  border-collapse: collapse;
  border: 1px solid #dee2e6;

  th,
  td {
    border: 1px solid #dee2e6;
    padding: 0.875rem;
  }
  th {
    background: #f8f9fa;
  }
}
