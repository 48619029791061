.loading {
  position: absolute;
  padding: 0.3rem;
  width: 4.2rem;
  height: 4.2rem;
  background-color: rgb(229 229 229);
  border-radius: 100%;
  top: 8rem;
  left: 50%;
  z-index: 10000;
  transform: translateX(-50%);
  animation: slipDown 0.5s ease-in-out;

  @keyframes slipDown {
    0% {
      top: -1rem;
      opacity: 0;
    }
    40% {
      top: 4.5rem;
      opacity: 0.2;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
      top: 8rem;
    }
  }
}
