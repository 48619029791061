.guideline {
  font-family: 'Times New Roman', serif;
  text-align: justify;
  color: black;

  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  ol {
    counter-reset: list;
  }
  ol > li {
    list-style: none;
    padding-bottom: 5px;
    p {
      display: inline;
    }
  }
  ol > li:before {
    content: counter(list, roman) ') ';
    counter-increment: list;
  }
  section {
    margin-bottom: 2rem;
    > div {
      margin-top: 1rem;
    }
  }
  p,
  span {
    font-size: 1.125rem;
  }
  img.center {
    display: block;
    margin: 0px auto;
  }
  img.max-width {
    max-width: 44rem;
  }
  .blue {
    color: var(--blue-600);
  }

  @media screen and (min-width: 576px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @media screen and (min-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @media screen and (min-width: 992px) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  @media screen and (min-width: 1200px) {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}

.pdf-container {
  height: calc(100vh - 9.1rem);
}
