
.side-bar{
    background-color: var(--cyan-600);
    min-width: 280px;
}

.info-card{
    flex-grow: 1;
    width: 100%;
}

/* CSS Research and Teaching */
.custom-card .p-card-subtitle {
    margin-bottom: 0px; /* Adjust this value as needed */
}

.custom-card .p-card-content {
    padding-top: 0; /* Adjust this value as needed */
    padding-bottom: 0em; /* Adjust this value as needed */
}
 
.custom-link-paper:hover{
    text-decoration: underline;
}