.evaluation-table-m01 {
  table,
  td,
  tr,
  th {
    border-style: solid;
    border-width: 1px;
  }
  th,
  td {
    padding: 4px 4px 4px 4px;
  }
  font-size: medium;
}
