.financial-adjustment {
  font-family: 'Times New Roman', Times, serif !important;
  h4 {
    margin-top: 7px;
    margin-bottom: 7px;
    color: #0000ff;
    font-size: 1.25rem;
    font-weight: bold;
  }

  p {
    font-size: 1.25rem;
    color: 'black';
  }

  table {
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 1.125rem;
  }

  .p-dropdown-label {
    padding: 0;
  }
}
