.layout-sidebar-cyan {
  background-color: var(--cyan-600);
  .layout-menu ul span:hover {
    background-color: transparent !important;
  }

  .layout-menu .layout-root-menuitem li span.active {
    background-color: var(--cyan-700) !important;
    .layout-menuitem-text {
      font-weight: 600 !important;
    }
  }

  .layout-menuitem-root-text {
    color: rgba(255, 255, 255, 0.6) !important;
  }

  .layout-menuitem-icon,
  .layout-menuitem-text {
    color: rgba(255, 255, 255, 1) !important;
  }
}

.layout-sidebar {
  @include scrollbars(0.7em, rgb(190, 196, 202), transparent);
  position: fixed;
  width: 280px;
  height: calc(100vh - 9rem);
  z-index: 999;
  overflow-y: auto;
  user-select: none;
  top: 7rem;
  left: 2rem;
  transition: transform $transitionDuration, left $transitionDuration;
  border-radius: $borderRadius;
  padding: 0.5rem 1.5rem;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  user-select: none;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--surface-900);
      margin: 0.75rem 0;
    }

    > a {
      display: none;
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
    }

    > ul {
      max-height: auto;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    span {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: var(--text-color);
      cursor: pointer;
      padding: 0.5rem 0.75rem;
      border-radius: $borderRadius;
      transition: background-color $transitionDuration, box-shadow $transitionDuration;

      .layout-menuitem-icon {
        font-size: 1.3rem;
        margin-right: 0.6rem;
      }

      .layout-menuitem-text {
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform $transitionDuration;
      }

      &.rotated-icon {
        .layout-menuitem-icon {
          transform: rotate(90deg);
        }
      }

      &.active-route {
        font-weight: 700;
        color: var(--primary-color);
      }

      &:hover {
        background-color: var(--surface-hover);
      }

      &:focus {
        @include focused-inset();
      }
    }

    ul {
      overflow: hidden;
      max-height: 0;
      border-radius: $borderRadius;

      li {
        span {
          margin-left: 1rem;
        }

        li {
          span {
            margin-left: 2rem;
          }

          li {
            span {
              margin-left: 2.5rem;
            }

            li {
              span {
                margin-left: 3rem;
              }

              li {
                span {
                  margin-left: 3.5rem;
                }

                li {
                  span {
                    margin-left: 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.layout-submenu-enter-active {
  max-height: 1000px !important;
  transition: max-height 1s ease-in-out;
}

.layout-submenu-enter-done {
  max-height: 1000px !important;
}

.layout-submenu-exit {
  max-height: 1000px !important;
}

.layout-submenu-exit-active {
  overflow: hidden;
  max-height: 0 !important;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}
