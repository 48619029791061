@media screen and (max-width: 580px) {
  .col-4 {
    width: 50%;
  }
}

.inner-table {
  border: none !important;

  & > td,
  th,
  thead,
  tbody,
  tr {
    border: none !important;
  }
}
