.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 7rem 2rem 2rem 4rem;
  transition: margin-left $transitionDuration;
}

.layout-main {
  @include scrollbars(0.7em, rgb(190, 196, 202), rgb(238, 238, 238));
  flex: 1 1 auto;
}

.layout-wrapper {
  min-height: 100vh;
}
