.highlighted {
    /* Define the styles for highlighted state */
    border: 2px solid #ffcc00 !important; /* Yellow border for highlighting */
    // border: 2px solid #22C55E !important; /* Green border for "Verified" */

    // transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Add a smooth transition effect */
  }

  /* Define the styles for highlighted state */
.highlighted-verified {
  border: 2px solid #00cc00 !important; /* Green border for "Verified" */
  background-color: #ccffcc !important; /* Light green background for "Verified" */
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out !important; /* Add a smooth transition effect */
}

.highlighted-uncertain {
  border: 2px solid #ffcc00 !important; /* Yellow border for "Uncertain" */
  background-color: #ffffcc !important; /* Light yellow background for "Uncertain" */
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out !important; /* Add a smooth transition effect */
}

.highlighted-not-found {
  border: 2px solid #ff0000 !important; /* Red border for "Not Found" */
  background-color: #ffcccc !important; /* Light red background for "Not Found" */
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out !important; /* Add a smooth transition effect */
}

/* Define the styles for the verification tag */
.verification-tag {
  cursor: pointer;
  font-size: x-small;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #00cc00; /* Green background for verification */
  color: #fff; /* White text color */
  font-weight: bold;
  white-space: nowrap;
}

.uncertain-tag {
  cursor: pointer;
  font-size: x-small;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #ffcc00; /* Green background for verification */
  color: #fff; /* White text color */
  font-weight: bold;
  white-space: nowrap;
}

.not-found-tag {
  cursor: pointer;
  font-size: x-small;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #ff0000; /* Green background for verification */
  color: #fff; /* White text color */
  font-weight: bold;
  white-space: nowrap;
}


