.specialize-review-edit {
    font-family: 'Times New Roman', Times, serif !important;
    h5 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 3px;
    }
  
    .card {
      padding: 0;
    }
  
    .line {
      border-top-style: dotted;
    }
  
    .right-button {
      float: right;
    }
  }
  