.prequalification-review-create {
  font-family: 'Times New Roman', Times, serif !important;
  div {
    font-size: 1.125rem;
  }
  h4,
  h5 {
    font-weight: 600;
  }

  h4 {
    font-size: 1.4rem;
    margin-top: 16px;
    margin-bottom: 14px;
  }

  h5 {
    font-size: 1.25rem;
    margin-bottom: 12px;
  }

  .card {
    padding: 0;
  }

  .line {
    border-top-style: dotted;
  }

  .right-button {
    float: right;
  }
  tbody {
    vertical-align: baseline;
  }
}
.margin-top {
  margin-top: 5px;
}

.sub-col {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
}
