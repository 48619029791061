.catalogue-table {
  font-family: 'Times New Roman', serif;
  text-align: justify;
  color: black;
  font-size: 1.125rem;

  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  thead th {
    background-color: #f8f9fa;
  }
  th,
  td {
    border: 1px solid #ddd;
    padding: 5px;
  }
  tr:nth-child(1) {
    background-color: #f8f9fa;
    text-align: center;
  }
  td:nth-child(1),
  td:nth-child(2) {
    text-align: center;
  }

  td a[href] {
    font-size: 1.125rem;
    font-weight: 500;
    color: var(--blue-500) !important;
    margin-left: 4px;
  }

  ul {
    margin: 8px 0;
  }
}
