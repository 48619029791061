.terms li {
    margin: 0 0 0.5rem 0;
}

hr {
    border: none; /* Loại bỏ đường viền hiện có */
    height: 2px; /* Đặt độ dày của đường ngang */
    background-color: #000; /* Đặt màu sắc cho đường ngang */
    margin: auto; 
    width: 20rem;
}
.first-line-indent {
    p {
        text-indent: 2em !important;
        margin: 0 0 0.5rem 0;
        line-height: 1.5;
    }
    .red-text-color {
        color: #ff0000;
    }
  }
