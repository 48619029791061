.financial-adjustment-container {
  max-height: calc(100vh - 9rem);
  overflow-y: auto;
  border-radius: 12px;
  border: 1px solid var(--surface-border);
  background-color: white;

  &::-webkit-scrollbar {
    width: 0.7em;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(190, 196, 202);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(238, 238, 238);
    border-radius: 20px;
  }

  // IE
  & {
    scrollbar-face-color: rgb(190, 196, 202);
    scrollbar-track-color: rgb(238, 238, 238);
  }
}
