.financial-review-create {
    font-family: 'Times New Roman', Times, serif !important;
  
    h5 {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 3px;
    }
  
    .inline-block .field {
      margin-bottom: 0;
    }
  }
  