.report-form-container {
  font-family: 'Times New Roman', Times, serif !important;
  font-size: 1.2rem;
  h4 {
    color: #0000ff;
    font-size: 1.25rem;
    font-weight: bold;
  }
  h6 {
    font-weight: bold;
    font-size: 1.125rem;
  }

  table {
    font-family: 'Times New Roman', Times, serif !important;
    font-size: 1.125rem;
  }
}
