/*#region Step*/
.p-steps {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  box-shadow: -1px -5px 10px grey;
  background-color: white;
  ul {
    flex-wrap: wrap;
    li {
      padding: 13px;
    }
    .p-steps-item.p-highlight .p-steps-number {
      background: var(--cyan-600);
      color: #ffffff;
    }
  }
  .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
}
/*#endregion Step*/

/*#region Toast*/
.p-toast-summary {
  font-size: 1.2rem;
}
.p-toast-detail {
  font-size: 1.1rem;
}
/*#endregion Toast*/

/*#region Tooltip*/
.p-tooltip-bottom.p-tooltip,
.p-tooltip-top.p-tooltip {
  max-width: 350px !important;
}
.p-tooltip-bottom.p-tooltip .p-tooltip-text,
.p-tooltip-top.p-tooltip .p-tooltip-text {
  background: rgba(252, 237, 237, 0.9) !important;
  color: var(--red-600) !important;
  border: 1px solid var(--red-600) !important;
  font-weight: 500 !important;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow,
.p-tooltip-top.p-tooltip .p-tooltip-arrow {
  border-bottom-color: var(--red-600);
}
/*#endregion Tooltip*/

/*#region BlockUI*/
.p-blockui {
  opacity: 0.4 !important;
}
/*#endregion BlockUI*/

/*#region FileUpload*/
.p-fileupload.p-invalid {
  border: 1px solid #e24c4c;
  border-radius: 6px;
}
/*#endregion FileUpload*/

/*#region Autocomplete*/
.p-autocomplete-panel {
  overflow-y: auto;
}
/*#endregion Autocomplete*/
