.layout-topbar {
  position: fixed;
  height: 5rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--cyan-600);
  transition: left $transitionDuration;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);

  .layout-topbar-logo {
    display: flex;
    align-items: center;
    color: var(--cyan-50);
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 12px;

    img {
      height: 2.5rem;
      margin-right: 0.5rem;
    }

    &:focus {
      @include focused();
    }
  }

  .layout-topbar-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: var(--cyan-50);
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    transition: background-color $transitionDuration;

    &:hover {
      color: var(--cyan-50);
      background-color: var(--cyan-700);
    }

    &:focus {
      @include focused();
    }

    i {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
      display: none;
    }

    .notify-badge {
      position: absolute;
      top: 3.75rem;
      right: 0;
      font-size: 0.75rem;
      align-items: center;
      justify-content: center;
      max-height: 80vh;
      background: #ffffff;
      color: #495057;
      border: 0 none;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }

    .notify-badge::before {
      position: absolute;
      top: -10px;
      right: 10px;
      border: 5px solid;
      border-color: transparent transparent #ffffff transparent;
    }

    .notify-badge::after {
      position: absolute;
      top: -10px;
      right: 10px;
      border: 5px solid;
      border-color: transparent transparent #ffffff transparent;
    }

    .scroll-noti::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
      background-color: #F5F5F5;
    }

    .scroll-noti::-webkit-scrollbar
    {
      width: 8px;
      background-color: #F5F5F5;
    }

    .scroll-noti::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #aaa8a8;
    }

    .scroll-noti::-webkit-scrollbar-button
    {
      display: none;
    }

    .hr-custom {
      border: 1.5px solid #aaa8a8;
    }

      }

  .layout-menu-button {
    margin-right: 2rem;
  }

  .layout-topbar-menu-button {
    display: none;

    i {
      font-size: 1.25rem;
    }
  }

  .layout-topbar-menu {
    margin: 0 0 0 auto;
    padding: 0;
    list-style: none;
    display: flex;

    .layout-topbar-button {
      margin-left: 1rem;
    }
  }
}

#overlay_panel_profile {
  .p-overlaypanel-content {
    padding: 10px 0;
  }
  .profile-menu {
    span,
    li a {
      display: block;
      padding: 8px 10px;
      padding-left: 20px;
      cursor: pointer;
      &:hover {
        background-color: #e9ecef;
      }
    }
    hr {
      margin: 0.5rem 0;
    }
  }
}

@media (max-width: 991px) {
  .layout-topbar {
    justify-content: space-between;

    .layout-topbar-logo {
      width: auto;
    }

    .layout-menu-button {
      margin-right: 0;
    }

    .layout-topbar-menu-button {
      display: inline-flex;
      margin-left: 0;
    }

    .layout-topbar-menu {
      margin-left: 0;
      position: absolute;
      flex-direction: column;
      background-color: var(--surface-overlay);
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
        0px 1px 4px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 1rem;
      right: 2rem;
      top: 5rem;
      min-width: 15rem;
      display: none;
      -webkit-animation: scalein 0.15s linear;
      animation: scalein 0.15s linear;

      &.layout-topbar-menu-mobile-active {
        display: block;
        .layout-topbar-button {
          color: var(--cyan-900);
          &:hover {
            color: var(--cyan-50);
          }
        }
      }

      .layout-topbar-button {
        margin-left: 0;
        display: flex;
        width: 100%;
        height: auto;
        justify-content: flex-start;
        border-radius: 12px;
        padding: 1rem;

        i {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        span {
          font-weight: medium;
          display: block;
        }
      }
    }
  }
}
