.p-editor-container.p-invalid {
  border: 1px solid #e24c4c;
  border-radius: 6px;
}

.hide-image-button .ql-image {
  display: none !important;
}

.surface-200 .ql-editor {
  background-color: #eeeeee !important;
}
