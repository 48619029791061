.invalid-file-input{
  .p-fileupload-buttonbar{
    border-top: 1px solid #f70606 !important;
    border-left: 1px solid #f70606 !important;
    border-right: 1px solid #f70606 !important;
    border-radius: 2px !important;
  }
  .p-fileupload-content{
    border-bottom: 1px solid #f70606 !important;
    border-left: 1px solid #f70606 !important;
    border-right: 1px solid #f70606 !important;
    border-radius: 2px !important;
  }
}
